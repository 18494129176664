import React from 'react';
import { Link } from 'gatsby';

//import { rhythm, scale } from '../utils/typography';

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props;
    const rootPath = `${__PATH_PREFIX__}/`;
    let header;

    if (location.pathname === rootPath) {
      header = (
        <h1
          style={{
            //...scale(1.0),
            //marginBottom: rhythm(1.5),
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h1>
      );
    } else {
      header = (
        <h3
          style={{
            //fontFamily: `Montserrat, sans-serif`,
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h3>
      );
    }
    return (
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          //maxWidth: rhythm(26),
          //padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        <header className={'page-header'}>{header}</header>
        <main>{children}</main>
        <footer
          style={{
            marginTop: '50px',
            fontFamily: `Montserrat, sans-serif`,
          }}
          className={'page-footer'}
        >
          <div style={{ width: '100%', textAlign: 'left' }}>
            <a
              href="/terms"
              style={{ marginRight: '5px', textDecoration: 'none' }}
            >
              Terms and Conditions
            </a>
            <a
              href="/privacy"
              style={{
                marginRight: '5px',
                marginLeft: '5px',
                textDecoration: 'none',
              }}
            >
              Pricacy Policy
            </a>
            <a
              href="/contact-us"
              style={{ marginLeft: '5px', textDecoration: 'none' }}
            >
              Contact Us
            </a>
            <br />
          </div>
        </footer>
      </div>
    );
  }
}

export default Layout;
